
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './header';
import React,{Component} from 'react';
import {apiBaseUrl} from './apiUtils';
import axios from 'axios';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Footer from './Footer/footer';
let context  = null;
export default class App extends Component {
  constructor(props){
    super();
    this.state={
      email:"",
      success: "",
      visible: false,
      formErrors: { email: '', phone: '' },
      formValid: true,
      emailValid: false,
      phoneValid: false,

    };
    this.submitForm = this.submitForm.bind(this);
    context = this;
  }

  submitForm(e){
    this.setState({ showError: false });
    if (!this.isValidate()) {
      this.setState({ showError: true });
      return true;


    }

    this.setState({ success: false });
    e.preventDefault();
    debugger;
    axios.post(apiBaseUrl+"businusquery/query",
  {
      
     "email": this.state.email,
     
   
    "source":"Businus.dashboard",
    "queryType":"Get-in-touch",
    "subject":"Get in Touch",
    "application":"businusdashboard",
      "type":1
  })
    .then(function (response) {
    if(response.status==200){
      context.setState({ success: true });
      console.log(response);
    }else{
     
    }
      
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  

  closeSubs = () => {
    this.setState({ success: false });
  }

  onChange = (e) => {

    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    }, () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Invalid';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
    });
  }


  errorClass(error) {
    return (error.length === 0 ? '' : 'is-invalid');
  }

  isValidate() {
    var status = false;
    if (this.state.emailValid) {
      status = true;
    }
    return status;
  }


  hide() {
    this.setState({ visible: false });
}
  
  render(){
  return (
    <div className="App" style={{overflowX:'hidden',overflowY:'hidden'}}>
    <Header/>

    <div className="row">

<div className="col-md-2"></div>
 

    <div className="col-md-8  box-container3">





    <img className="clipArt" src={require("./images/businusDashboardClipart.png")}/>
  
    <div className="sub-desc-div"> <h2 className="sub-desc" >Looking to Deliver Impeccable Client Experience</h2></div>
    <div className="desc-div"> <h2 className="desc" >You Have Reached Your Destination</h2></div>
   

    <form id="email">
      <div className="row website">
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <div className = "email-div">Get In touch</div></div>
          <div className="col-md-4">
                 <input type="email" size="24" onChange={this.onChange} value={this.state.email} placeholder="Enter Your Email" className={`form-control email-input-get-in-touch ${this.errorClass(this.state.formErrors.email)}`}  id="subscribeemail" name="email"/></div>
                 <div className="col-md-4">     <button onClick={this.submitForm} className="btn btn-primary email-submit-button" disabled={!this.isValidate()} >Submit </button></div>
          <div id="success1"></div>

          </div>

          <div className="row mobile">
       <div className="row">
       <div className="col-12">
          <div className = "email-div">Get In touch</div>
          </div>
          </div>
          <div className="row">
          <div className="col-9">
                 <input type="email" size="24" onChange={this.onChange} value={this.state.email} placeholder="Enter Your Email" className={`form-control email-input-get-in-touch ${this.errorClass(this.state.formErrors.email)}`}  id="subscribeemail" name="email"/></div>
                 <div className="col-2">     <button onClick={this.submitForm} className="btn btn-primary email-submit-button" disabled={!this.isValidate()} >Submit </button></div>
                 <div className="col-2">   </div>
                 <div id="success1"></div>
          </div>
          </div>
        </form>
  </div>

    <div className="col-md-2"></div>
    </div>

<Footer/>
<Rodal visible={this.state.success} height={100} className="pop-up" animation="flip" onClose={this.closeSubs} >
  <div><p className="rodal-text">Thank You for your request. We'll get back to you in some time.</p></div>
</Rodal>
    
    </div>
  );
}}


export const baseFilePath = "template-engine-images/uploadedImages"


/* LIVE */
 export const apiBaseUrl = "https://p494o2epsk.execute-api.us-east-1.amazonaws.com/live/"; 


/* TEST */
/*export const apiBaseUrl = "https://5tueagkdg6.execute-api.us-east-1.amazonaws.com/dev/";*/


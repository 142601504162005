import React,{Component} from 'react';

import "./footer.css";
import {apiBaseUrl} from '../apiUtils';
import axios from 'axios';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';



{/*import {apiBaseUrl} from '../apiUtils';*/}


let context  = null;
export default class Footer extends Component {

  constructor(props){
    super();
    this.state={
      email:"",
      success:"",
      visible: false,
      formErrors: { email: '', phone: '' },
      formValid: true,
      emailValid: false,
      phoneValid: false,
    };
    this.submitForm = this.submitForm.bind(this);
    context = this;
  }
  closeSubs = () => {
    this.setState({ success: false });
  }
  submitForm(e){
    this.setState({ success: false });
    e.preventDefault();
    axios.post(apiBaseUrl+"businusquery/query",
  {
      
     "email": this.state.email,
     
   
    "source":"businus.dashboard",
    "queryType":"subscribers",
    "subject":"Subscription",
    "application":"businusdashboard",
      "type":1
  })
    .then(function (response) {
    if(response.status==200){
      context.setState({ success: true });
      console.log(response);
    }else{
     
    }
      
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  

  onChange = (e) => {

    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    }, () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Invalid';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
    });
  }


  errorClass(error) {
    return (error.length === 0 ? '' : 'is-invalid');
  }

  isValidate() {
    var status = false;
    if (this.state.emailValid) {
      status = true;
    }
    return status;
  }


  hide() {
    this.setState({ visible: false });
}
  
  
 render(){
   return(
<div>
<div className="footer big">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 col-12">
        <h4 className="title-widget" id="footer-head">Links</h4>
        <ul>
          <div ><a href="http://businus.services" target="_blank" className = "footer-item">Businus.Services</a></div>
          <div ><a href="http://businus.school" target="_blank" className = "footer-item">Businus.School</a></div>
        
        </ul>
      </div>
   

      <div className="col-lg-3 col-12">
        <h4 id="footer-head">Find Us</h4>
        <a  href="https://www.facebook.com/businusdotcom" target="_blank" style={{marginTop:"-10px"}} className="fa fa-facebook"></a>
        <a  href="https://twitter.com/businusdotcom" target="_blank" className="fa fa-twitter"></a>
        <a  href="https://www.linkedin.com/company/businusdotcom" target="_blank" className="fa fa-linkedin"></a>
        <a href="https://www.instagram.com/businusdotcom" target="_blank" className="fa fa-instagram"></a>
        <a href="https://www.youtube.com/channel/UCyuXLlNdLhlgF97f89yYrpg" target="_blank" className="fa fa-youtube"></a>
      </div>
      
     
      <div className="col-lg-3 col-12">
        <h4 id="footer-head">Subscribe to our Newsletter</h4>
        <form id="subscribe">
          <div className = "email-div-f">Your Email</div>
                 <input type="email" size="24" onChange={this.onChange} value={this.state.email} className={`form-control email-input ${this.errorClass(this.state.formErrors.email)}`}  id="subscribeemail" name="email"/>
                 <button onClick={this.submitForm} className="btn btn-primary" disabled={!this.isValidate()} >Subscribe </button>
          <div id="success1"></div>
        </form>
   </div>
    
     
      
      <div className="col-lg-3 col-12">
   <h4 id="footer-head">
   <img style={{paddingLeft:'50px',width:'270px',marginTop:'-20px'}} src={require("../images/businusDashboardLogoWhite.png")} />
     
   </h4>
   </div>
  
      </div>

     

    <div className="row">
      
      
      


     

    

      
      <div className="col-lg-3 col-sm-2">
       
      </div>

    

    

    </div>
  </div>
  </div>



{/*

  <div className="footer mobile">
  <div className="container">
    <div className="row footrow">
      <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-5">
        <h4 className="title-widget" id="footer-head">Trainings</h4>
        <ul>
          <div className = "footer-item"><Link id="footer-item" to="/strategic">Strategic</Link></div>
          <div className = "footer-item"><Link id="footer-item" to="/functional">Functional</Link></div>
        
        </ul>
      </div>

      <div className="col-lg-3 col-sm-2 col-5">
        
      </div>
      <div class="col-1 col-sm-2 mobile"></div>
      </div>
      <div className="row footrow">
      <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-5">
      <h4 className="title-widget" id="footer-head">Docs</h4>
      
      </div>
    
      <div class="col-1 col-sm-2 mobile"></div>
      </div>
     

    <div className="row footrow">
   
    <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-5">
        <h4 className="title-widget" id="footer-head">Company</h4>
        <ul>
          <div className = "footer-item"><Link id="footer-item" target="_blank" to="/about-us">About us</Link></div>
          <div className = "footer-item"><Link id="footer-item" target="_blank" to="/contact-us">Contact us</Link></div>
          <div className = "footer-item"><Link id="footer-item" target="_blank" to="/contact">Work with us</Link></div>
          <div className = "footer-item"><Link id="footer-item" target="_blank" to="/Site Map">Site Map</Link></div>
          
        </ul>
      </div>
     
      
     
    
      <div className="col-lg-3 col-sm-2 col-5">
        <h4 id="footer-head">Find Us</h4>
        <a href="http://www.facebook.com/Value-Plus-1229617493809082" target="_blank" className="fa fa-facebook"></a>
        <a href="Http://www.twitter.com/valueplusio" target="_blank" className="fa fa-twitter"></a>
        <a href="http://www.linkedin.com/in/value-plus-260b86119/" target="_blank" className="fa fa-linkedin"></a>
      </div>
    
      </div>
      <div className="row footrow">
     
      <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-10">
        <h4 id="footer-head">Subscribe to our Newsletter</h4>
        <form id="subscribe">
          <div className = "email-div">Your Email</div>
                 <input type="email" size="24" onChange={this.onChange} value={this.state.email} className={`form-control email-input ${this.errorClass(this.state.formErrors.email)}`} id="subscribeemail" name="email"/>
                 <button onClick={this.submitForm} className="buttonsubs" disabled={!this.isValidate()} >Subscribe </button>
          <div id="success1"></div>
        </form>
      </div>
    </div>
      

      
    <div className="row footrow">
     
    <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-10">
      <img style={{paddingLeft:'3px'}} src={require("../images/businus.comLogo.png")} />
        
      </div>
      </div>

      </div>
  </div>*/}
  

<footer className="  copyright">
  <div className="container">
  
  <div class="row"> <div className="col-md-4"></div><div className="col-md-4">
    <p className=" text-center text-white">Copyright &copy; businusdashboard.com 2022</p>
    </div>
    <div className="col-md-4"></div>
  </div>
</div>
</footer>
<Rodal visible={this.state.success} height={100} className="pop-up" animation="flip" onClose={this.closeSubs} >
  <div><p>Thank you for subscribing to us</p></div>
</Rodal>
</div>

    );
  }
}
